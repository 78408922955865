import {QUESTIONS_API} from 'apiEndpoints.js';
import actions from 'better_consult/actions/types.js';
import {addSteps} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import http from 'http.js';
import CheckpointQuestion from 'questions/components/CheckpointQuestion.js';
import {snakeToCamelObjectKeys} from 'utils/case_converter.js';

/*
    Clinical:
    N/A

    Technical:
    This step will hit the global questions API and add the
    corresponding GlobalQuestionSteps based on the reponse.

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckpointQuestion,
    reloadAction: reloadGlobalQuestionCheckpointStep,
    submitAction: submitGlobalQuestionCheckpointStep,
    undoAction: undoGlobalQuestionCheckpointStep,
});

export function reloadGlobalQuestionCheckpointStep() {
    return function(dispatch, getState) {
        const previousStep = getState().form.previousSteps.find(
            (step) => step.component.type === 'GlobalQuestionCheckpointStep',
        );
        if (previousStep) {
            return {};
        }
    };
}

export function submitGlobalQuestionCheckpointStep({stepId}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            // request global questions, add to store, create steps
            const {id} = getState().data.consultations.consultation;
            let questions = await http.get({
                url: `${QUESTIONS_API}global/?consultation=${id}`,
            });
            questions = questions.map(snakeToCamelObjectKeys);
            dispatch({
                type: actions.questions.GET_GLOBAL_QUESTIONS_SUCCESS,
                questions,
            });
        }

        const globalQuestions = getState().questions.global.items;
        const steps = [];
        for (const question of globalQuestions) {
            const answers = question.answers.map((answer) => ({
                id: answer.id,
                text: answer.name,
            }));
            steps.push({
                type: 'GlobalQuestionStep',
                props: {
                    qid: question.id,
                    title: question.name,
                    questionType: question.type,
                    hasNoAnswerOption: question.allowNoAnswer,
                    answers,
                },
            });
        }
        dispatch(addSteps({steps, parentId: stepId}));
    };
}

export function undoGlobalQuestionCheckpointStep() {
    return async function(dispatch) {
        dispatch({
            type: actions.questions.GET_GLOBAL_QUESTIONS_SUCCESS,
            questions: [],
        });
    };
}
