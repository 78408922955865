import {defineMessages} from 'react-intl';

import actions from 'better_consult/actions/types.js';
import {
    submitMedicationCourse,
    undoMedicationCourse,
} from 'consultations/actions/consultationActions.js';
import {createStep, searchMedications} from 'consultations/helpers.js';
import AutoCompleteQuestion from 'questions/components/AutoCompleteQuestion.js';

const messages = defineMessages({
    disabledResultText: {
        id: 'consultations.MedicationForPrescriptionStep.disabledResultText',
        defaultMessage: 'disabledAnswerText',
    },
    questionTitle: {
        id: 'consultations.MedicationForPrescriptionStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    searchFieldPlaceholder: {
        // eslint-disable-next-line max-len
        id:
            'consultations.MedicationForPrescriptionStep.searchFieldPlaceholder',
        defaultMessage: 'searchFieldPlaceholder',
    },
    noAnswerText: {
        id: 'consultations.MedicationForPrescriptionStep.noAnswerText',
        defaultMessage: 'noAnswerText',
    },
});

/*
    Clinical:
    Prompts the patient for the medication for which they need a prescription.

    Technical:
    N/A

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: AutoCompleteQuestion,
    props: {searchAction: searchMedications},
    mapState: (store, props) => {
        const disabledResults = [];
        Object.values(store.data.consultations.medicationCourses).forEach(
            (item) => {
                if (item.medicationSearchTerm) {
                    disabledResults.push({id: item.medicationSearchTerm.id});
                }
            },
        );

        return {
            title: props.intl.formatMessage(messages.questionTitle),
            placeholder: props.intl.formatMessage(
                messages.searchFieldPlaceholder,
            ),
            noAnswerText: props.intl.formatMessage(messages.noAnswerText),
            disabledResultText: props.intl.formatMessage(
                messages.disabledResultText,
            ),
            disabledResults,
        };
    },
    reloadAction: reloadMedicationForPrescriptionStep,
    submitAction: submitMedicationForPrescriptionStep,
    undoAction: undoMedicationForPrescriptionStep,
});

export function reloadMedicationForPrescriptionStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const currentSteps = store.form.steps.filter(
            (step) => step.component.type === 'MedicationForPrescriptionStep',
        );
        const currentStepIndex = currentSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'MedicationForPrescriptionStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {props} = previousStep.component;
            const {searchResults, searchValue} = props.searchData;
            const cmcId = props.cmcId;
            const cmc = store.data.consultations.medicationCourses[cmcId];
            const medication = cmc.medicationSearchTerm || cmc.medication;
            const result = {
                medicationCourse: cmc,
                searchResults,
                searchValue,
            };
            if (medication) {
                result.answer = {id: medication.id, text: medication.name};
            }
            return result;
        }
    };
}

export function submitMedicationForPrescriptionStep(
    {stepId, answer, medicationCourse, searchResults, searchValue},
    reload,
) {
    return async function (dispatch) {
        await dispatch(
            submitMedicationCourse(
                {
                    stepId,
                    medicationCourse,
                    medicationSearchTermId: answer?.id,
                    isPrescriptionReasonForVisit: true,
                },
                reload,
            ),
        );
        dispatch({
            type: actions.form.SET_STEP_ATTR,
            payload: {
                stepId,
                field: 'component.props.searchData',
                value: {
                    searchValue,
                    searchResults,
                },
            },
        });
    };
}

export function undoMedicationForPrescriptionStep({stepId}) {
    return async function (dispatch) {
        await dispatch(undoMedicationCourse({stepId}));
    };
}
