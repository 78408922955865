import {defineMessages} from 'react-intl';

import {updateMedicationCourse} from 'consultations/actions/consultationActions.js';
import {setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep} from 'consultations/helpers.js';
import RadioQuestion from 'questions/components/RadioQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.MedicationComplianceStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
    noAnswer: {
        id: 'consultations.MedicationComplianceStep.noAnswer',
        defaultMessage: 'noAnswer',
    },
    yesAnswer: {
        id: 'consultations.MedicationComplianceStep.yesAnswer',
        defaultMessage: 'yesAnswer',
    },
    notSure: {
        id: 'consultations.MedicationComplianceStep.notSure',
        defaultMessage: 'notSure',
    },
});

export default createStep({
    question: RadioQuestion,
    mapState: (store, props) => ({
        title: props.intl.formatMessage(messages.questionTitle),
        answers: [
            {
                id: 'yes',
                text: props.intl.formatMessage(messages.yesAnswer),
            },
            {
                id: 'no',
                text: props.intl.formatMessage(messages.noAnswer),
            },
            {
                id: 'notSure',
                text: props.intl.formatMessage(messages.notSure),
            },
        ],
    }),
    reloadAction: reloadMedicationComplianceStep,
    submitAction: submitMedicationComplianceStep,
    undoAction: undoMedicationComplianceStep,
});

export function reloadMedicationComplianceStep(stepId) {
    return function(dispatch, getState) {
        const {form} = getState();
        const currentSteps = form.steps.filter(
            (step) => step.component.type === 'MedicationComplianceStep',
        );
        const currentStepIndex = currentSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = form.previousSteps.filter(
            (step) => step.component.type === 'MedicationComplianceStep',
        );
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {answer} = previousStep.component.props;
            return {
                answer,
            };
        }
    };
}

export function submitMedicationComplianceStep(
    {stepId, answer, props},
    reload,
) {
    return async function(dispatch) {
        if (answer) {
            if (!reload && answer.id !== 'notSure') {
                await dispatch(
                    updateMedicationCourse(props.medicationCourseId, {
                        field: 'compliance',
                        toReplace: answer.id.toUpperCase(),
                    }),
                );
            }
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'answer',
                    value: answer,
                }),
            );
        }
    };
}

export function undoMedicationComplianceStep({stepId, props}) {
    return async function(dispatch) {
        await dispatch(
            updateMedicationCourse(props.medicationCourseId, {
                field: 'compliance',
                toReplace: '',
            }),
        );
        dispatch(
            setDataOnStep({
                stepId,
                field: 'answer',
                value: null,
            }),
        );
    };
}
