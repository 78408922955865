import React from 'react';

import {CONSULTATIONS_CONSULTATIONS_API} from 'apiEndpoints.js';
import actions from 'better_consult/actions/types.js';
import {createStep} from 'consultations/helpers.js';
import http from 'http.js';
import ChoiceQuestion from 'questions/components/ChoiceQuestion.js';
import {types} from 'questions/helpers.js';

/*
    Clinical:
    N/A

    Technical:
    The Question model has a bool flag is_global. When this is True, those
    questions will be shown to all patients at the end of the Consultation.

    Dependencies:
    N/A

    Future:
    It would be nice if, in future, we can specify whereabouts in the
    consultation we should ask each GlobalQuestion
*/
export default createStep({
    question: ChoiceQuestion,
    reloadAction: reloadGlobalQuestionStep,
    submitAction: submitGlobalQuestionStep,
    undoAction: undoGlobalQuestionStep,
});

export function reloadGlobalQuestionStep(stepId) {
    return function (dispatch, getState) {
        const store = getState();
        const globalQuestionSteps = store.form.steps.filter(
            (step) => step.component.type === 'GlobalQuestionStep',
        );
        const currentStepIndex = globalQuestionSteps.findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = store.form.previousSteps.filter(
            (step) => step.component.type === 'GlobalQuestionStep',
        );
        const previousStep = previousSteps[currentStepIndex];

        if (!previousStep) {
            return;
        }
        const currentStep = globalQuestionSteps[currentStepIndex];
        const {answers, qid, questionType} = currentStep.component.props;

        const selectedAnswers = store.questions.items.find(
            (item) => item.id === qid,
        ).selectedAnswers;

        if (!selectedAnswers) {
            return;
        }

        const filteredAnswers = answers.filter((answer) =>
            selectedAnswers.includes(answer.id),
        );

        switch (questionType) {
            case types.GROUPING:
                return {answers: selectedAnswers};
            case types.RADIO:
                return {answer: filteredAnswers[0] || {id: null}};
            case types.CHECKBOX:
                return {answers: filteredAnswers};
            default:
                return;
        }
    };
}

export function submitGlobalQuestionStep(
    {answers = [], answer, props},
    reload,
) {
    return async function (dispatch, getState) {
        if (!reload) {
            const state = getState();
            const {qid, questionType} = props;
            const consultationId = state.data.consultations.consultation.id;
            if (questionType === types.RADIO && answer.id) {
                answers.push(answer);
            }

            const data =
                questionType === types.GROUPING
                    ? {answers}
                    : {answers: answers.map((i) => i.id)};
            await http.post({
                // eslint-disable-next-line max-len
                url: `${CONSULTATIONS_CONSULTATIONS_API}${consultationId}/questions/${qid}/`,
                data,
            });
            dispatch({
                type: actions.questions.SUBMIT_GLOBAL_ANSWERS_SUCCESS,
                payload: {
                    answers: data.answers,
                    qid,
                },
            });
        }
    };
}

export function undoGlobalQuestionStep({props}) {
    return async function (dispatch, getState) {
        const state = getState();
        const consultationId = state.data.consultations.consultation.id;
        const {qid} = props;
        await http.delete({
            // eslint-disable-next-line max-len
            url: `${CONSULTATIONS_CONSULTATIONS_API}${consultationId}/questions/${qid}/`,
        });
        dispatch({
            type: actions.questions.UNDO_SUBMIT_GLOBAL_ANSWERS_SUCCESS,
            payload: {
                qid,
            },
        });
    };
}
