import {defineMessages} from 'react-intl';

import {
    PROBLEM_TYPE_CONDITION,
    PROBLEM_TYPE_SYMPTOM,
} from 'clinical/helpers.js';
import {
    submitCondition,
    submitSymptom,
    undoCondition,
    undoSymptom,
    updateMedicationCourse,
} from 'consultations/actions/consultationActions.js';
import {setDataOnStep} from 'consultations/actions/formActions.js';
import {createStep, searchProblems} from 'consultations/helpers.js';
import ChoiceQuestion from 'questions/components/ChoiceQuestion.js';
import {types} from 'questions/helpers.js';

export const messages = defineMessages({
    currentlyTakingTitle: {
        id: 'consultations.MedicationForProblemsStep.currentlyTakingTitle',
        defaultMessage: 'currentlyTakingTitle',
    },
    disabledResultText: {
        id: 'consultations.MedicationForProblemsStep.disabledResultText',
        defaultMessage: 'disabledResultText',
    },
    findOtherConditionSymptom: {
        id:
            'consultations.MedicationForProblemsStep.findOtherConditionSymptom',
        defaultMessage: 'findOtherConditionSymptom',
    },
    notCurrentlyTakingTitle: {
        id: 'consultations.MedicationForProblemsStep.notCurrentlyTakingTitle',
        defaultMessage: 'notCurrentlyTakingTitle',
    },
    searchPlaceholder: {
        id: 'consultations.MedicationForProblemsStep.searchPlaceholder',
        defaultMessage: 'searchPlaceholder',
    },
    questionTitle: {
        id: 'consultations.MedicationForProblemsStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    What is the patient taking this medication for? We use the indications
    provided by Ron which are mapped to MedicationCategory.

    This step is really important because this will catch people who don't know
    why they're taking their medication.

    Technical:
    N/A

    Dependencies:
    One of these steps is generated per medication selected in the
    MedicationStep

    Future:
    - Pull indications from TGA response (currently from Ron's DrHx database)
    - We should probably rename this to MedicationIndicationStep or similar
*/

export default createStep({
    question: ChoiceQuestion,
    props: {
        // TODO: should be able to search symptom as well
        searchAction: searchProblems,
    },
    mapState: (store, props) => ({
        additionalAnswerText: getAdditionalAnswerText(props),
        disabledResultText: props.intl.formatMessage(
            messages.disabledResultText,
        ),
        searchPlaceholder: props.intl.formatMessage(
            messages.searchPlaceholder,
        ),
        title: getTitle(props),
    }),
    reloadAction: reloadMedicationForProblemsStep,
    submitAction: submitMedicationForProblemsStep,
    undoAction: undoMedicationForProblemsStep,
});

function getAdditionalAnswerText(props) {
    if (typeof props.isRepeatPrescription !== 'undefined') {
        return props.intl.formatMessage(messages.findOtherConditionSymptom);
    }
    return null;
}

function getTitle(props) {
    if (props.isRepeatPrescription) {
        return props.intl.formatMessage(messages.currentlyTakingTitle);
    } else if (props.isRepeatPrescription === false) {
        return props.intl.formatMessage(messages.notCurrentlyTakingTitle);
    } else {
        return props.intl.formatMessage(messages.questionTitle);
    }
}

function matchSteps(steps, currentStep) {
    return steps.filter(
        (step) =>
            step.component.type === 'MedicationForProblemsStep' &&
            step.component.props.medicationCourseId ===
                currentStep.component.props.medicationCourseId,
    );
}

export function reloadMedicationForProblemsStep(stepId) {
    return function(dispatch, getState) {
        const {form, data} = getState();
        const currentStep = form.steps.find((step) => step.id === stepId);
        if (!currentStep) {
            return;
        }
        const currentStepIndex = matchSteps(form.steps, currentStep).findIndex(
            (step) => step.id === stepId,
        );
        const previousSteps = matchSteps(form.previousSteps, currentStep);
        const previousStep = previousSteps[currentStepIndex];
        if (previousStep) {
            const {
                questionType,
                medicationCourseId,
                problem,
                shouldAddSteps,
                transitionToOtherFlow,
            } = previousStep.component.props;
            const {
                conditions,
                symptoms,
            } = data.consultations.medicationCourses[medicationCourseId];

            const selectedConditions = conditions.map((condition) => ({
                id: condition.id,
                text: condition.name,
                type: PROBLEM_TYPE_CONDITION,
            }));
            const selectedSymptoms = symptoms.map((symptom) => ({
                id: symptom.id,
                text: symptom.name,
                type: PROBLEM_TYPE_SYMPTOM,
            }));
            const selectedAnswers = [
                ...selectedConditions,
                ...selectedSymptoms,
            ];
            const previousAnswers = previousStep.component.props.answers;
            const combinedAnswers = previousAnswers.concat(
                selectedAnswers.filter(
                    (selectedAnswer) =>
                        !previousAnswers.some(
                            (previousAnswer) =>
                                selectedAnswer.id === previousAnswer.id,
                        ),
                ),
            );
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'answers',
                    value: combinedAnswers,
                }),
            );
            // for condition / symptom flow
            if (problem) {
                dispatch(
                    setDataOnStep({
                        stepId,
                        field: 'problem',
                        value: problem,
                    }),
                );
            }
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'shouldAddSteps',
                    value: shouldAddSteps,
                }),
            );
            dispatch(
                setDataOnStep({
                    stepId,
                    field: 'transitionToOtherFlow',
                    value: transitionToOtherFlow,
                }),
            );
            if (questionType === types.RADIO) {
                return {
                    answer: selectedAnswers[0] || {id: null},
                };
            }
            return {answers: selectedAnswers};
        }
    };
}

export function submitMedicationForProblemsStep(
    {answer, answers, props, stepId},
    reload,
) {
    return async function(dispatch) {
        const {
            medicationCourseId,
            isRepeatPrescription,
            transitionToOtherFlow,
        } = props;
        const conditionIds = [];
        const symptomIds = [];
        if (answer?.type) {
            switch (answer.type.toLowerCase()) {
                case PROBLEM_TYPE_CONDITION:
                    if (transitionToOtherFlow) {
                        await dispatch(
                            submitCondition(
                                {
                                    stepId,
                                    conditionId: answer.id,
                                    displayText: answer.text,
                                    isPresentingProblem: isRepeatPrescription,
                                    medicationCourseId,
                                },
                                reload,
                            ),
                        );
                    }
                    conditionIds.push(answer.id);
                    break;
                case PROBLEM_TYPE_SYMPTOM:
                    if (transitionToOtherFlow) {
                        await dispatch(
                            submitSymptom(
                                {
                                    stepId,
                                    symptomId: answer.id,
                                    displayText: answer.text,
                                    medicationCourseId,
                                },
                                reload,
                            ),
                        );
                    }
                    symptomIds.push(answer.id);
                    break;
            }
        } else if (answers && !reload) {
            answers.forEach((answer) => {
                if (answer.type.toLowerCase() === PROBLEM_TYPE_CONDITION) {
                    conditionIds.push(answer.id);
                } else if (
                    answer.type.toLowerCase() === PROBLEM_TYPE_SYMPTOM
                ) {
                    symptomIds.push(answer.id);
                }
            });
        }
        if (conditionIds.length && !reload) {
            await dispatch(
                updateMedicationCourse(medicationCourseId, {
                    field: 'conditions',
                    toReplace: conditionIds,
                }),
            );
        }
        if (symptomIds.length && !reload) {
            await dispatch(
                updateMedicationCourse(medicationCourseId, {
                    field: 'symptoms',
                    toReplace: symptomIds,
                }),
            );
        }
    };
}

export function undoMedicationForProblemsStep({stepId, answer, props}) {
    return async function(dispatch) {
        const {
            transitionToOtherFlow,
            medicationCourseId,
            shouldAddSteps,
        } = props;
        if (answer?.type && transitionToOtherFlow && shouldAddSteps) {
            switch (answer.type.toLowerCase()) {
                case PROBLEM_TYPE_CONDITION:
                    await dispatch(undoCondition(stepId));
                    break;
                case PROBLEM_TYPE_SYMPTOM:
                    await dispatch(undoSymptom(stepId));
                    break;
            }
        }

        await dispatch(
            updateMedicationCourse(medicationCourseId, {
                field: 'conditions',
                toReplace: [],
            }),
        );
        await dispatch(
            updateMedicationCourse(medicationCourseId, {
                field: 'symptoms',
                toReplace: [],
            }),
        );
        dispatch(
            setDataOnStep({
                stepId,
                field: 'conditions',
                value: undefined,
            }),
        );
        dispatch(
            setDataOnStep({
                stepId,
                field: 'symptoms',
                value: undefined,
            }),
        );
    };
}
