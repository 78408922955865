import {defineMessages} from 'react-intl';

import {updateConsultationSymptom} from 'consultations/actions/consultationActions.js';
import {createStep, findPreviousProblemStep} from 'consultations/helpers.js';
import CheckboxQuestion from 'questions/components/CheckboxQuestion.js';

const messages = defineMessages({
    questionTitle: {
        id: 'consultations.ImpactsOnFunctionStep.questionTitle',
        defaultMessage: 'questionTitle',
    },
});

/*
    Clinical:
    Are the symptom(s) causing any impacts on a patient's daily life (e.g.
    unable to walk up stairs)?

    Technical:
    Only asked when the Symptom has impacts_on_function

    Dependencies:
    N/A

    Future:
    N/A
*/

export default createStep({
    question: CheckboxQuestion,
    mapState: (store, props) => ({
        subTitle: props.problem.displayText,
        title: props.intl.formatMessage(messages.questionTitle),
    }),
    reloadAction: reloadImpactsOnFunction,
    submitAction: submitImpactsOnFunction,
    undoAction: undoImpactsOnFunction,
});

export function reloadImpactsOnFunction(stepId) {
    return function(dispatch, getState) {
        const store = getState();
        const previousStep = findPreviousProblemStep({
            form: store.form,
            stepType: 'ImpactsOnFunctionStep',
            stepId,
        });
        if (previousStep) {
            const {answers, problem} = previousStep.component.props;
            const {consultationSymptoms} = store.data.consultations;
            const impactsOnFunction =
                consultationSymptoms[problem.consultationSymptomId]
                    .impactsOnFunction;
            if (impactsOnFunction) {
                const selectedAnswers = answers.filter((answer) =>
                    impactsOnFunction.some((iof) => answer.id === iof.id),
                );
                return {
                    answers: selectedAnswers,
                };
            }
        }
    };
}

export function submitImpactsOnFunction({stepId, answers}, reload) {
    return async function(dispatch, getState) {
        if (!reload) {
            const store = getState();
            const step = store.form.steps.find((step) => step.id === stepId);
            const {problem} = step.component.props;
            let toAdd = [];
            if (answers) {
                toAdd = answers.map((answer) => answer.id);
            }
            await dispatch(
                updateConsultationSymptom(problem.consultationSymptomId, {
                    field: 'impactsOnFunction',
                    toAdd,
                }),
            );
        }
    };
}

export function undoImpactsOnFunction({stepId, answers}) {
    return async function(dispatch, getState) {
        const store = getState();
        const step = store.form.steps.find((step) => step.id === stepId);
        const {problem} = step.component.props;
        let toRemove = [];
        if (answers) {
            toRemove = answers.map((answer) => answer.id);
        }
        await dispatch(
            updateConsultationSymptom(problem.consultationSymptomId, {
                field: 'impactsOnFunction',
                toRemove,
            }),
        );
    };
}
