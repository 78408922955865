import React from 'react';
import {defineMessages, injectIntl, FormattedMessage} from 'react-intl';

import MenuBar from 'core/components/MenuBar.js';
import analytics from 'core/google_analytics.js';
import Icon from 'utils/components/Icon.js';

const messages = defineMessages({
    contactEmail: {
        id: 'consultations.InvalidToken.contactEmail',
        defaultMessage: 'contactEmail',
    },
    missingToken: {
        id: 'consultations.InvalidToken.missingToken',
        defaultMessage: 'missingToken',
    },
    missingTokenHeader: {
        id: 'consultations.InvalidToken.missingTokenHeader',
        defaultMessage: 'missingTokenHeader',
    },
});

export class InvalidToken extends React.Component {
    componentDidMount() {
        analytics.send('event', {
            eventCategory: 'Form loading',
            eventAction: 'Open shortened url',
            eventLabel: 'Missing token screen',
            consultationToken: this.props.token,
            data: this.props.data,
        });
    }

    render() {
        const {intl} = this.props;
        return (
            <>
                <MenuBar menuType="consultation" />
                <section className="view-status">
                    <figure>
                        <Icon name="IconInvalidToken" />
                    </figure>
                    <header>
                        <h1 data-test-id="consultation-status-title">
                            {intl.formatMessage(messages.missingTokenHeader)}
                        </h1>
                        <p data-test-id="consultation-status-message">
                            <FormattedMessage
                                defaultMessage="missingToken"
                                id="consultations.InvalidToken.missingToken"
                                values={{
                                    contactEmail: (
                                        // eslint-disable-next-line max-len
                                        <a href="mailto:support@betterconsult.com">
                                            {intl.formatMessage(
                                                messages.contactEmail,
                                            )}
                                        </a>
                                    ),
                                }}
                            />
                        </p>
                    </header>
                </section>
            </>
        );
    }
}

export default injectIntl(InvalidToken);
